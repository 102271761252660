// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from 'types/rootState';
import { SessionState } from 'modules/session';

const CSRFTokenField = ({ csrfToken }: SessionState) => {
  return (
    <div style={{ display: 'none', height: 0 }}>
      <input type="hidden" name="authenticity_token" value={csrfToken} />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state.session
  };
};

export default connect(mapStateToProps)(CSRFTokenField);
