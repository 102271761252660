export const DOMAINS: string[] = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'comcast.net',
  'me.com',
  'msn.com',
  'live.com',
  'mac.com',
  'sbcglobal.net',
  'outlook.com',
  'icloud.com',
  'att.net',
  'verizon.net',
  'ymail.com',
  'cox.net',
  'bellsouth.net',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'mail.com',
  'mail.ru',
  'yahoo.ca',
  'hotmail.fr'
];
