import { SessionState, ViewedFeatures, MessagesType } from './types';

const getLogOut = () => ({
  type: 'SESSION/LOG_OUT'
});

const getLogOutSuccess = () => ({
  type: 'SESSION/LOG_OUT_SUCCESS'
});

const getLogOutFailure = () => ({
  type: 'SESSION/LOG_OUT_FAILURE'
});

const fetchSession = () => ({
  type: 'SESSION/FETCH'
});

const setupSessionComplete = (showHardGate: boolean) => ({
  type: 'SESSION/SETUP_COMPLETE',
  showHardGate
});

const setupMessages = (messages: MessagesType[]) => ({
  type: 'SESSION/SETUP_MESSAGES',
  messages
});

const fetchSessionComplete = (success: boolean, data?: SessionState) => ({
  type: 'SESSION/FETCH_SESSION_COMPLETE',
  success,
  data
});

const setSessionId = (sessionId: string | null) => ({
  type: 'SESSION/SET_SESSION_ID',
  sessionId
});

const fetchSessionId = () => ({
  type: 'SESSION/FETCH_SESSION_ID'
});

const setPhoneNumber = (phoneNumber: string) => ({
  type: 'SESSION/SET_PHONE_NUMBER',
  phoneNumber
});

const addViewedFeature = (viewedFeature: ViewedFeatures) => ({
  type: 'SESSION/ADD_VIEWED_FEATURE',
  viewedFeature
});

const addViewedFeatureSuccess = (viewedFeature: ViewedFeatures) => ({
  type: 'SESSION/ADD_VIEWED_FEATURE_SUCCESS',
  viewedFeature
});

const toggleProductRevenueToolbar = () => ({
  type: 'SESSION/TOGGLE_ADMIN_REVENUE_TOOLBAR'
});

export {
  getLogOut,
  getLogOutSuccess,
  getLogOutFailure,
  setupSessionComplete,
  setupMessages,
  fetchSession,
  fetchSessionComplete,
  setSessionId,
  fetchSessionId,
  setPhoneNumber,
  addViewedFeature,
  addViewedFeatureSuccess,
  toggleProductRevenueToolbar
};
