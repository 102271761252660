export type AuthenticationInterfaceState = {
  logo: string;
  modalImageUrl: string;
  links: {
    termsUrl: string;
    privacyPolicyUrl: string;
    facebookUrl: string;
    loginUrl: string;
    newUserUrl: string;
    forgotPasswordUrl: string;
    newPasswordUrl: string;
    googleUrl: string;
  };
  finalSlideMobile: {
    title: string;
    description: string;
  };
  signupCta: string;
  changePasswordCta: string;
  loginCta: string;
  forgotPasswordCta: string;
  emailTooltipMessage: string;
  socialFooterCta: string;
  forgotPasswordDescription: string;
  imageDesktop: {
    title: string;
    description: string;
    image: string;
  };
  imageMobile: {
    title: string;
    description: string;
    image: string;
  };
  avatarUrls: Array<string>;
};

const reducer = (state: AuthenticationInterfaceState | null = null) => {
  return state;
};

export default reducer;
