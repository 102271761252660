import { AuthenticationStateType } from './types';

const defaultAuthenticationState: AuthenticationStateType = {
  user: {
    email: '',
    context: 'signup',
    errors: {
      email: false,
      password: false,
      resetPasswordToken: false
    }
  },
  referredEmail: '',
  forgotPasswordSuccess: null
};

const reducer = (
  state: AuthenticationStateType = defaultAuthenticationState
) => {
  return state;
};

export default reducer;
