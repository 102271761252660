// @flow

import './JournalSignupView.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';
import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import AuthForm from 'components/Shared/AuthForm';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const modalCopy = {
  title: 'The 50 Books Every Guy Should Read',
  button: 'Send it'
};

const JournalSignupView = ({ onChangeView }: Props) => (
  <div className="JournalSignupView tc">
    <div className="JournalSignupView__wrapper">
      <h2 className="JournalSignupView__title fg-condensed--medium fw--600">
        {modalCopy.title}
      </h2>
      <h4 className="JournalSignupView__subtitle fg-condensed--xxsmall">
        <span className="JournalSignupView__subtitle JournalSignupView__subtitle--yellow">
          Sign up for the Huckberry Newsletter
        </span>
        <br />
        and we&apos;ll email you our most popular article of all time
      </h4>
      <div className="JournalSignupView__auth--wrapper">
        <AuthenticationContainer trait="signup-from-journal-soft-gate-modal">
          {(authenticationProps: AuthenticationProps) => (
            <AuthForm
              view="signup"
              submitText={modalCopy.button}
              textColor="dark"
              {...authenticationProps}
            />
          )}
        </AuthenticationContainer>
      </div>
      <div className="JournalSignupView__footer fg-condensed--body">
        Already a member?{' '}
        <div
          onClick={() => onChangeView('login')}
          className="JournalSignupView__footer--cta link--nudge"
        >
          Log In
        </div>
      </div>
    </div>
  </div>
);

export default JournalSignupView;
