// @flow

import './FavoritesLoginView.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';

import AuthForm from 'components/Shared/AuthForm';
import CTALink from 'components/Shared/AuthForm/CTALink';

import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import { type Views } from 'modules/auth-modal/types';

import huckberryTreeLogoUrl from 'assets/images/shared/icon-huckberry-tree-black.svg';

type Props = {|
  onChangeView: (view: Views) => void
|};

const FavoritesLoginView = ({ onChangeView }: Props) => (
  <div className="FavoritesLoginView tc">
    <img className="FavoritesLoginView__tree-logo" src={huckberryTreeLogoUrl} />
    <h3 className="FavoritesLoginView__title fg-condensed--medium fw--600">
      Keep Track of Your Favorites
    </h3>
    <h4 className="FavoritesLoginView__subtitle fg-condensed--body">
      Log In or Sign Up below
    </h4>
    <div className="FavoritesLoginView__auth--wrapper">
      <AuthenticationContainer trait="signup-from-favorites-modal">
        {(authenticationProps: AuthenticationProps) => (
          <AuthForm
            view="login"
            onChangeView={onChangeView}
            submitText="Log In"
            textColor="dark"
            {...authenticationProps}
          />
        )}
      </AuthenticationContainer>
    </div>

    <CTALink
      textBefore="Not a member?"
      linkText="Sign Up"
      dark
      onClick={() => onChangeView('signup')}
    />
  </div>
);

export default FavoritesLoginView;
