// @flow

import './BackInStockSignupView.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';

import AuthForm from 'components/Shared/AuthForm';
import CTALink from 'components/Shared/AuthForm/CTALink';

import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import { type Views } from 'modules/auth-modal/types';

import huckberryTreeLogoUrl from 'assets/images/shared/icon-huckberry-tree-black.svg';

type Props = {|
  onChangeView: (view: Views) => void
|};

const BackInStockSignupView = ({ onChangeView }: Props) => (
  <div className="BackInStockSignupView tc">
    <img
      className="BackInStockSignupView__tree-logo"
      src={huckberryTreeLogoUrl}
    />
    <h3 className="BackInStockSignupView__title fg-condensed--medium fw--600">
      Be the First to Know
    </h3>
    <h4 className="BackInStockSignupView__subtitle fg-condensed--body">
      Sign up to receive an email when this item comes back in stock
    </h4>
    <div className="BackInStockSignupView__auth--wrapper">
      <AuthenticationContainer trait="signup-from-back-in-stock-modal">
        {(authenticationProps: AuthenticationProps) => (
          <AuthForm
            view="signup"
            onChangeView={onChangeView}
            submitText="Sign Up"
            textColor="dark"
            {...authenticationProps}
          />
        )}
      </AuthenticationContainer>
    </div>

    <CTALink
      textBefore="Already a member?"
      linkText="Log in"
      dark
      onClick={() => onChangeView('login')}
    />
  </div>
);

export default BackInStockSignupView;
