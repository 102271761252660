// @flow

import differenceWith from 'lodash/differenceWith';
import filter from 'lodash/filter';
import eventBus from 'utils/eventBus';

import type {
  UserFavoritesState,
  UserFavoritesActions,
  UserFavoriteProduct
} from './types';

const defaultState = {
  isLoading: false,
  loadingMore: false,
  allProductsLoaded: false,
  fetchingHash: false,
  favorites: [],
  favoritesHashLoaded: false,
  favoritesHash: null,
  userHasFavorites: false,
  meta: {
    currentPage: 0,
    perPage: 0,
    totalPages: 0
  },
  error: false
};

const filterDuplicateFavorites = (
  newFavorites: Array<UserFavoriteProduct>,
  prevFavorites: Array<UserFavoriteProduct>
): Array<UserFavoriteProduct> =>
  differenceWith(
    newFavorites,
    prevFavorites,
    (
      newFavorites: UserFavoriteProduct,
      currentFavorites: UserFavoriteProduct
    ) => newFavorites.product.sku === currentFavorites.product.sku
  );

const reducer = (
  state: UserFavoritesState = defaultState,
  action: UserFavoritesActions
): UserFavoritesState => {
  switch (action.type) {
    case 'USER_FAVORITES/FETCH':
      return {
        ...state,
        isLoading: true
      };
    case 'USER_FAVORITES/FETCH_MORE_PRODUCTS':
      return {
        ...state,
        loadingMore: state.meta.currentPage > 0 && !state.allProductsLoaded
      };
    case 'USER_FAVORITES/FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        loadingMore: false,
        favorites: [
          ...state.favorites,
          ...filterDuplicateFavorites(action.payload.favorites, state.favorites)
        ],
        meta: action.payload.meta,
        allProductsLoaded:
          action.payload.meta.currentPage === action.payload.meta.totalPages ||
          action.payload.meta.totalPages === 0,
        userHasFavorites: Boolean(action.payload.favorites.length > 0)
      };
    case 'USER_FAVORITES/FETCH_HASH':
      return {
        ...state,
        fetchingHash: true
      };
    case 'USER_FAVORITES/FETCH_HASH_SUCCESS':
      return {
        ...state,
        fetchingHash: false,
        favoritesHashLoaded: true,
        favoritesHash: action.payload.favoritesHash,
        userHasFavorites: Boolean(
          Object.values(action.payload.favoritesHash).length > 0
        )
      };
    case 'USER_FAVORITES/FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        favoritesHashLoaded: false,
        allProductsLoaded: true,
        error: action.error
      };
    case 'USER_FAVORITES/ADD':
      return { ...state, error: false, isLoading: true };
    case 'USER_FAVORITES/ADD_SUCCESS':
      eventBus.dispatch('user-favorite-alert-message', {
        product: {
          sku: action.payload?.product?.sku,
          name: action.payload?.product?.name,
          brand: action.payload?.product?.brand,
          price: action.payload?.price,
          url: action.payload?.product?.url,
          imageUrl: action.payload?.cartImageUrl,
          masterVariantId: action.payload?.id
        },
        isProductFavorite: true,
        isVisible: true,
        showFullAlertMessage: state.favorites.length === 0
      });

      return {
        ...state,
        isLoading: false,
        favorites: [action.payload, ...state.favorites],
        favoritesHash: Object.assign(state.favoritesHash, {
          [action.payload.id]: true
        }),
        userHasFavorites: true
      };
    case 'USER_FAVORITES/ADD_FAILURE':
      return {
        ...state,
        error: action.err,
        isLoading: false
      };
    case 'USER_FAVORITES/REMOVE_SUCCESS':
      eventBus.dispatch('user-favorite-alert-message', {
        product: {
          sku: action.payload?.product?.sku,
          name: action.payload?.product?.name,
          brand: action.payload?.product?.brand,
          price: action.payload?.price,
          url: action.payload?.product?.url,
          imageUrl: action.payload?.cartImageUrl,
          masterVariantId: action.payload?.id
        },
        isProductFavorite: false,
        isVisible: true,
        showFullAlertMessage: false
      });

      return {
        ...state,
        isLoading: false,
        favorites: filter<UserFavoriteProduct>(
          state.favorites,
          (item: UserFavoriteProduct) => {
            return item.id !== action.payload?.id;
          }
        ),
        favoritesHash: Object.assign(state.favoritesHash, {
          [action.payload.id]: false
        })
      };
    case 'USER_FAVORITES/REMOVE_FAILURE':
      return {
        ...state,
        error: action.err,
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
