// @flow

import reducer from './reducer';
import * as actionCreators from './action-creators';

export type {
  AccountState,
  AccountDetailsNewData,
  EmailFrequency,
  RestockNotificationType
} from './types';

export const {
  getAccountDetails,
  updateAccountDetails,
  getOrderHistory,
  updateEmailFrequency,
  getAccountList,
  deleteNotification
} = actionCreators;

export default reducer;
