import React from 'react';
import { useSelector } from 'react-redux';
import mailcheck from 'mailcheck';

import { DOMAINS } from './MailCheckDomains';

import oauthPostSubmitForm from 'utils/oauthPostSubmitForm';
import { validateEmail, validatePassword } from 'utils/formValidator';

import { events as analytics, trackAction } from 'modules/analytics';
import { AuthenticationProps, Trait, FromType } from 'modules/authentication';
import { ApplicationState } from 'types/rootState';

type Props = {
  children: (props: AuthenticationProps) => React.ReactNode;
  trait: Trait;
  email?: string;
};

const AuthenticationContainer = ({
  children,
  trait,
  email: outsideEmail
}: Props) => {
  const location = useSelector(
    (state: ApplicationState) => state.router.location
  );
  const authenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );
  const authenticationInterface = useSelector(
    (state: ApplicationState) => state.authenticationInterface
  );
  const authentication = { ...authenticationState, ...authenticationInterface };
  const {
    signupCta,
    loginCta,
    forgotPasswordCta,
    forgotPasswordDescription,
    forgotPasswordSuccess,
    socialFooterCta,
    changePasswordCta,
    emailTooltipMessage,
    imageDesktop,
    imageMobile,
    modalImageUrl,
    avatarUrls,
    links,
    user
  } = authentication;

  const [email, setEmail] = React.useState(
    outsideEmail || user.email || location.query.kemail || ''
  );
  const [password, setPassword] = React.useState('');
  const [isEmailErrorVisible, setEmailErrorVisible] = React.useState(
    user.errors.email
  );
  const [isPasswordErrorVisible, setPasswordErrorVisible] = React.useState(
    user.errors.password
  );
  const [
    isPasswordResetErrorVisible,
    setPasswordResetErrorVisible
  ] = React.useState(user.errors.resetPasswordToken);
  const [suggestedEmail, setSuggestedEmail] = React.useState('');

  const prevPathRef = React.useRef<string>('');

  React.useEffect(() => {
    prevPathRef.current = location.pathname;
  });

  React.useEffect(() => {
    prevPathRef.current !== location.pathname && resetErrors();
  }, [location.pathname]);

  const resetErrors = () => {
    setEmailErrorVisible(false);
    setPasswordErrorVisible(false);
    setPasswordResetErrorVisible(false);
  };

  const getEmailErrorMessage = () => {
    if (suggestedEmail.length > 0) {
      return `Did you mean ${suggestedEmail}?`;
    }
    return 'Please enter a valid email address';
  };

  const getPasswordErrorMessage = () => {
    if (
      /\/login/.test(location.pathname) ||
      authentication.user.context === 'login'
    ) {
      return 'Invalid email or password';
    }

    return 'Password must be at least 6 characters';
  };

  const onClickFacebookSignup = (from: FromType, newAuth = true) => {
    const trackingEvent = newAuth
      ? analytics.USER_REGISTRATION_SUBMITTED
      : analytics.USER_LOG_IN_SUBMITTED;

    trackAction(trackingEvent, {
      trigger: from || '',
      authorization_type: 'facebook',
      label: trait
    });

    oauthPostSubmitForm(links.facebookUrl, trait);
  };

  const onClickGoogleSignup = (from: FromType, newAuth = true) => {
    const trackingEvent = newAuth
      ? analytics.USER_REGISTRATION_SUBMITTED
      : analytics.USER_LOG_IN_SUBMITTED;

    trackAction(trackingEvent, {
      trigger: from || '',
      authorization_type: 'google',
      label: trait
    });

    oauthPostSubmitForm(links.googleUrl, trait);
  };

  const errorCheck = (event: React.SyntheticEvent<HTMLFormElement>) => {
    const isEmailErrorVisible = !validateEmail(email);
    const isPasswordErrorVisible = !validatePassword(password);
    if (isEmailErrorVisible || isPasswordErrorVisible) {
      event.preventDefault();
      setEmailErrorVisible(isEmailErrorVisible);
      setPasswordErrorVisible(isPasswordErrorVisible);
    }
  };

  const onSubmitSignupForm = (
    event: React.SyntheticEvent<HTMLFormElement>,
    from: FromType,
    checkError = true
  ) => {
    checkError && errorCheck(event);
    if (!isEmailErrorVisible || !isPasswordErrorVisible) {
      trackAction(analytics.USER_REGISTRATION_SUBMITTED, {
        trigger: from || '',
        authorization_type: 'email',
        label: trait
      });
    }
  };

  const onSubmitLoginForm = (
    event: React.SyntheticEvent<HTMLFormElement>,
    from: FromType
  ) => {
    trackAction(analytics.USER_LOG_IN_SUBMITTED, {
      trigger: from || '',
      authorization_type: 'email',
      label: trait
    });
  };

  const onSubmitForgotPasswordForm = (
    event: React.SyntheticEvent<HTMLFormElement>
  ) => {
    if (!email) {
      setSuggestedEmail('Please provide an email address.');
    }
    const emailError = !validateEmail(email);
    if (emailError) {
      event.preventDefault();
      setEmailErrorVisible(emailError);
    }
  };

  const onSubmitChangePasswordForm = (
    event: React.SyntheticEvent<HTMLFormElement>
  ) => {
    if (!validatePassword(password)) {
      event.preventDefault();
      setPasswordErrorVisible(isPasswordErrorVisible);
    }
  };

  const onChangeEmail = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value;
    const domain = email.split('@')[1];

    if (email && suggestedEmail === 'Please provide an email address.') {
      setSuggestedEmail('');
    }

    if (domain?.indexOf('.') > 0) {
      mailcheck.run({
        email,
        domains: DOMAINS,
        suggested: (suggestion: { full: string }) => {
          setEmailErrorVisible(true);
          setSuggestedEmail(suggestion.full);
        },
        empty: () => {
          const emailError = isEmailErrorVisible
            ? !validateEmail(email)
            : false;
          setEmailErrorVisible(emailError);
          setSuggestedEmail('');
        }
      });
    }
    setEmail(email);
  };

  const onChangePassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const enteredPassword = event.currentTarget.value;
    setPassword(enteredPassword);
    const passwordError = isPasswordErrorVisible
      ? !validatePassword(enteredPassword)
      : false;
    setPasswordErrorVisible(passwordError);
  };

  const sharedProps: AuthenticationProps = {
    email,
    password,
    isEmailErrorVisible,
    isPasswordErrorVisible,
    isPasswordResetErrorVisible,
    suggestedEmail,
    signupCta,
    loginCta,
    forgotPasswordCta,
    forgotPasswordDescription,
    forgotPasswordSuccess,
    socialFooterCta,
    changePasswordCta,
    emailTooltipMessage,
    user,
    imageDesktop,
    imageMobile,
    avatarUrls,
    modalImageUrl,
    termsUrl: links.termsUrl,
    privacyPolicyUrl: links.privacyPolicyUrl,
    newUserUrl: links.newUserUrl,
    loginUrl: links.loginUrl,
    forgotPasswordUrl: links.forgotPasswordUrl,
    newPasswordUrl: links.newPasswordUrl,
    responseErrors: user.errors,
    emailErrorMessage: getEmailErrorMessage(),
    passwordErrorMessage: getPasswordErrorMessage(),
    resetPasswordToken: location.query.reset_password_token,
    trait,
    context: user.context,
    // Actions
    resetErrors,
    onChangeEmail,
    onChangePassword,
    onClickFacebookSignup,
    onClickGoogleSignup,
    onSubmitSignupForm,
    onSubmitLoginForm,
    onSubmitForgotPasswordForm,
    onSubmitChangePasswordForm
  };

  return children(sharedProps);
};

export default AuthenticationContainer;
