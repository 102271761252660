// @flow

import './AuthForm.scss';

import * as React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import CSRFTokenField from 'components/utils/CSRFTokenField';

import useIsVisible from 'hooks/useIsVisible';

import SuggestedEmailInput from 'components/Authentication/SuggestedEmailInput';
import ResponsiveMedia from 'components/Shared/ResponsiveMedia/ResponsiveMedia';

import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import { type Views } from 'modules/auth-modal/types';

import facebookIcon from 'assets/images/shared/button-icon-facebook.svg';
import googleIcon from 'assets/images/shared/button-icon-google.svg';

const eyeOpenUrl = 'https://huckberry.imgix.net/softgate/eye-icon-open.png';
const eyeClosedUrl = 'https://huckberry.imgix.net/softgate/eye-icon-closed.png';

type Props = {|
  ...AuthenticationProps,
  view: 'login' | 'signup',
  submitText: string,
  textColor?: 'light' | 'dark',
  buttonColor?: 'light' | 'dark',
  showForgotPassword?: boolean,
  showPasswordHint?: boolean,
  autoFocus?: boolean,
  showFacebookButton?: boolean,
  showGoogleButton?: boolean,
  children?: React.Node,
  onChangeView?: (view: Views) => void
|};

const AuthForm = ({
  view,
  email,
  onChangeEmail,
  password,
  onChangePassword,
  onClickFacebookSignup,
  onClickGoogleSignup,
  onSubmitSignupForm,
  onSubmitLoginForm,
  newUserUrl,
  loginUrl,
  emailErrorMessage,
  isEmailErrorVisible,
  passwordErrorMessage,
  isPasswordErrorVisible,
  onChangeView,
  submitText,
  textColor,
  buttonColor,
  trait,
  showForgotPassword = true,
  showPasswordHint = true,
  autoFocus = true,
  showFacebookButton = true,
  showGoogleButton = true,
  children = null
}: Props) => {
  const browserWidth = useSelector((state) => state.ui?.browserWidth);
  const isMobile = browserWidth <= 800;

  const [isFormVisible, visibleRef] = useIsVisible(0.5);
  const [formHasBeenVisible, setFormHasBeenVisible] = React.useState(false);
  React.useEffect(() => {
    if (!isFormVisible || formHasBeenVisible) {
      return;
    }
    setFormHasBeenVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormVisible]);

  const [showPassword, updateShowPassword] = React.useState(false);
  const errorClasses = classnames('tl fg-condensed--xxsmall color--white', {
    'color--red': textColor === 'dark'
  });

  const emailError = isEmailErrorVisible && (
    <p className={errorClasses}>{emailErrorMessage}</p>
  );
  const passwordError = <p className={errorClasses}>{passwordErrorMessage}</p>;

  const formArguments = {
    onSubmit: view === 'signup' ? onSubmitSignupForm : onSubmitLoginForm,
    url: view === 'signup' ? newUserUrl : loginUrl
  };

  const inputForm = (
    <form
      className="AuthForm__form"
      onSubmit={(event) => formArguments.onSubmit(event, 'modal')}
      method="post"
      action={formArguments.url}
      ref={visibleRef}
    >
      <CSRFTokenField />

      <input type="hidden" name="auth_modal" value={'no_redirect'} />
      <input type="hidden" name="traits[]" value={trait} />

      <div className="AuthForm__input--row">
        <SuggestedEmailInput
          email={email}
          autoFocus={!isMobile && autoFocus}
          onChangeEmail={onChangeEmail}
          emailInputClassName={
            'AuthForm__input--email color--blue fg-condensed--body'
          }
          emailTooltip={''}
          emailError={emailError}
        />
      </div>
      <div className="AuthForm__input--row AuthForm__container--password">
        <input
          type={showPassword ? 'text' : 'password'}
          id="user_password"
          name="user[password]"
          placeholder={view === 'login' ? 'Enter Password' : 'Create Password'}
          className="AuthForm__input--password color--blue fg-condensed--body"
          value={password}
          onChange={onChangePassword}
        />
        <label
          onClick={() => updateShowPassword(!showPassword)}
          className="AuthForm__eyeball"
          htmlFor="user_password"
        >
          <ResponsiveMedia
            width={18}
            height={18}
            src={showPassword ? eyeClosedUrl : eyeOpenUrl}
          />
        </label>
        {isPasswordErrorVisible ? (
          passwordError
        ) : (
          <p
            className={classnames(
              'AuthForm__input--password-small fg-condensed--xxxsmall',
              {
                'color--white': textColor === 'light',
                'color--blue': textColor === 'dark'
              }
            )}
          >
            {view === 'signup' && showPasswordHint
              ? '6 Characters Minimum'
              : ''}
          </p>
        )}
      </div>

      {children && children}
      {formHasBeenVisible && (
        <button
          type="submit"
          className={classnames(
            'AuthForm__button uppercase fg-condensed--body',
            {
              'AuthForm__button--dark': buttonColor === 'dark',
              'AuthForm__button--light': buttonColor === 'light'
            }
          )}
        >
          {submitText}
        </button>
      )}
    </form>
  );

  return (
    <>
      {inputForm}
      {view === 'login' && showForgotPassword && (
        <p
          onClick={() => onChangeView && onChangeView('forgot-password')}
          className={classnames('AuthForm__password-reset link--nudge dib', {
            'color--white': textColor === 'light'
          })}
        >
          Forgot Your Password?
        </p>
      )}

      {(showFacebookButton || showGoogleButton) && (
        <div
          className={classnames('AuthForm__or fg-condensed--xxsmall tc', {
            'color--white': textColor === 'light',
            'color--blue AuthForm__dark': textColor === 'dark'
          })}
        >
          or
        </div>
      )}

      <div className="AuthForm__auth-buttons">
        {showFacebookButton && (
          <button
            onClick={() => onClickFacebookSignup('modal', true)}
            className="uppercase AuthForm__auth--button button--facebook fg-condensed--body"
          >
            <p>{view === 'login' ? 'Sign In with ' : 'Join with '}</p>
            <img src={facebookIcon} />
          </button>
        )}

        {showGoogleButton && (
          <button
            onClick={() => onClickGoogleSignup('modal', true)}
            className="uppercase AuthForm__auth--button button--google fg-condensed--body"
          >
            <p>{view === 'login' ? 'Sign In with ' : 'Join with '}</p>
            <img src={googleIcon} />
          </button>
        )}
      </div>
    </>
  );
};

export default AuthForm;
