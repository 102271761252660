// @flow

import type {
  AccountState,
  AccountDetailsNewData,
  EmailFrequency
} from './types';

export const getAccountDetails = () => ({
  type: 'ACCOUNT_DETAILS/FETCH'
});

export const getAccountDetailsSuccess = (data: AccountState) => ({
  type: 'ACCOUNT_DETAILS/FETCH_SUCCESS',
  data
});

export const getAccountDetailsFailure = (err: any) => ({
  type: 'ACCOUNT_DETAILS/FETCH_FAILURE',
  err
});

export const updateAccountDetails = (data: AccountDetailsNewData) => ({
  type: 'ACCOUNT_DETAILS/UPDATE',
  data
});

export const updateAccountDetailsSuccess = (data: AccountState) => ({
  type: 'ACCOUNT_DETAILS/UPDATE_SUCCESS',
  data
});

export const updateAccountDetailsFailure = (err: any) => ({
  type: 'ACCOUNT_DETAILS/UPDATE_FAILURE',
  err
});

export const getOrderHistory = (monthsBack: number) => ({
  type: 'ORDER_HISTORY/FETCH',
  monthsBack
});

export const getOrderHistorySuccess = (data: AccountState) => ({
  type: 'ORDER_HISTORY/FETCH_SUCCESS',
  data
});

export const getOrderHistoryFailure = (err: any) => ({
  type: 'ORDER_HISTORY/FETCH_FAILURE',
  err
});

export const updateEmailFrequency = (data: EmailFrequency) => ({
  type: 'EMAIL_FREQUENCY/UPDATE',
  data
});

export const updateEmailFrequencySuccess = () => ({
  type: 'EMAIL_FREQUENCY/UPDATE_SUCCESS'
});

export const updateEmailFrequencyFailure = () => ({
  type: 'EMAIL_FREQUENCY/UPDATE_FAILURE'
});

export const getAccountList = () => ({
  type: 'ACCOUNT_LIST/FETCH'
});

export const getAccountListSuccess = (data: any) => ({
  type: 'ACCOUNT_LIST/FETCH_SUCCESS',
  data
});

export const getAccountListFailure = (err: any) => ({
  type: 'ACCOUNT_LIST/FETCH_FAILURE',
  err
});

export const deleteNotification = (id: number) => ({
  type: 'RESTOCK_NOTIFICATION/DELETE',
  id
});

export const deleteNotificationSuccess = (id: number) => ({
  type: 'RESTOCK_NOTIFICATION/DELETE_SUCCESS',
  id
});

export const deleteNotificationFailure = (err: any) => ({
  type: 'RESTOCK_NOTIFICATION/DELETE_FAILURE',
  err
});
