import { BundlesDataType } from './types';

const getBundles = () => ({
  type: 'BUNDLES/GET'
});

const getBundlesSuccess = (bundlesData: BundlesDataType) => ({
  type: 'BUNDLES/GET_SUCCESS',
  bundlesData
});

const getBundlesFailure = () => ({
  type: 'BUNDLES/GET_FAILURE'
});

const setActiveBundle = () => ({
  type: 'BUNDLES/SET_ACTIVE_BUNDLE'
});

const setActiveBundleSuccess = (productId: number) => ({
  type: 'BUNDLES/SET_ACTIVE_BUNDLE_SUCCESS',
  productId
});

export {
  getBundles,
  getBundlesSuccess,
  getBundlesFailure,
  setActiveBundle,
  setActiveBundleSuccess
};
