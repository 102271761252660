// @flow

import './SignupEmailStep.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';
import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import { close } from 'modules/auth-modal';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const SignupEmailStep = ({ onChangeView }: Props) => {
  const dispatch = useDispatch();

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onTermsClickHandler = React.useCallback(() => dispatch(close()), [
    dispatch
  ]);

  return (
    <div className="SignupEmailStep">
      <div className="SignupEmailStep__wrapper tc">
        <p className="SignupEmailStep__eyebrow fg-condensed--medium fw--600">
          Be The First to Know
        </p>
        <p className="SignupEmailStep__title fg-condensed--medium fw--600">
          <span className="SignupEmailStep__title--yellow">
            Our Best Deals Are Coming
          </span>
        </p>
        <p className="SignupEmailStep__content fg-condensed--medium">
          Get exclusive access to our biggest sale
          <br />
          events and most coveted gifts of 2022.
        </p>
        <div className="SignupEmailStep__form">
          <AuthenticationContainer trait="signup-from-big-event-coming-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Send it"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Big Event Coming Soon View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>

      <div className="SignupEmailStep__footer fg-condensed--medium">
        By continuing you agree to our{' '}
        <AutoLink to="/terms" target="_blank" onClick={onTermsClickHandler}>
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default SignupEmailStep;
