// @flow

import 'config/polyfill';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import * as Sentry from '@sentry/react';

import store from 'store';
import history from 'store/history';
import App from 'components/App';
import supportedBrowsers from '__generated__/supportedBrowsers';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (_e) {
    // We could end up here due to CORS issues (when loaded in an iframe) or
    // IE bugs, both of which we'd also like to treat as if we're in an iframe.
    return true;
  }
};

if (typeof window !== 'undefined') {
  Sentry.init({
    dsn: window.ENV.sentryDsn,
    environment: window.ENV.sentryEnv,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    release: window.ENV.sentryRelease,
    sampleRate: 0.9,
    tracesSampleRate: 0.025,
    allowUrls: [/huckberry\.com/],
    ignoreErrors: [
      'ChunkLoadError',
      'Loading CSS chunk',
      // Error inside the Pocket app injected JS
      'pktAnnotationHighlighter',
      "Can't find variable: article",
      // Seems to be an error related to video exiting on iOS from injected JS
      /undefined is not an object.*webkitExitFullScreen/,
      // Error inside Instagram injected JS
      '_AutofillCallbackHandler',
      // Unknown injected JS
      '__firefox__',
      // Old browser error
      'In order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
      // Errors when user cancels page load
      // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      // Hardware or OS errors we can't do anything about
      'NS_ERROR_',
      // Extension Errors
      'safari-extension:',
      // Accessibe
      'acsbJS',
      // Injected JS from Google Docs
      'docs-homescreen-gb-container',
      // Ignores non-error promises
      'Non-Error promise rejection captured',
      // Third party
      'instantSearchSDKJSBridgeClearHighlight',
      // Third party container code
      'Blocked a frame with origin',
      'setIOSParameters',
      // Ignore hydration errors while volume is insanely high
      'Hydration failed',
      'error while hydrating',
      'Minified React error #418',
      'UnhandledRejection: Object captured as promise rejection with keys: config, data, status'
    ],
    beforeSend: (event: Sentry.Event): ?Sentry.Event => {
      if (inIframe()) {
        return null;
      }

      if (!supportedBrowsers.test(navigator.userAgent)) {
        return null;
      }

      return event;
    }
  });
}

const element = document.getElementById('App');

if (element) {
  const appContent = (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const errorStyles = {
          color: '#1d2b39',
          backgroundColor: 'white',
          padding: '50px 10px',
          height: '200px',
          textAlign: 'center',
          fontFamily: 'Arial'
        };

        if (
          (/^Loading chunk [0-9]+ failed/.test(error.message) ||
            /^Loading CSS chunk [0-9]+ failed/.test(error.message)) &&
          window.location.hash !== '#retry'
        ) {
          // the chunk might no longer be available due to a recent redeployment of the page
          // mark the page so we don't trigger reload infinitely
          window.location.hash = '#retry';
          window.location.reload(true);

          return null;
        }

        return (
          <div style={errorStyles}>
            <p>An error has ocurred. Please reload the page and try again.</p>
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              <p
                style={{
                  color: '#1d2b39',
                  fontWeight: '600',
                  paddingTop: '15px'
                }}
              >
                Reload
              </p>
            </button>
          </div>
        );
      }}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  );

  // Clear any potentially server rendered content.
  // $FlowIgnore[prop-missing]
  if (element.replaceChildren) {
    // $FlowIgnore[not-a-function]
    element.replaceChildren();
  } else {
    element.innerHTML = '';
  }

  createRoot(element).render(appContent);
}
