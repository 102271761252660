// @flow

import './ExitIntentEmailStep.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';
import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';
import AutoLink from 'components/utils/AutoLink';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const ExitIntentEmailStep = ({ onChangeView }: Props) => {
  const onChangeViewHandler = React.useCallback(
    (view: Views) => onChangeView(view),
    [onChangeView]
  );

  return (
    <div className="ExitIntentEmailStep">
      <div className="ExitIntentEmailStep__wrapper tc">
        <p className="ExitIntentEmailStep__eyebrow fg-condensed--medium fw--600">
          Before you go
        </p>
        <p className="ExitIntentEmailStep__title fg-condensed--medium fw--600">
          Stash{' '}
          <span className="ExitIntentEmailStep__title--yellow">
            Free Shipping
          </span>
          <span className="show--md"> for Later</span>
        </p>
        <p className="ExitIntentEmailStep__content fg-condensed--medium">
          Signup for our newsletter to lock in Free Shipping on your first order
        </p>
        <div className="ExitIntentEmailStep__form">
          <AuthenticationContainer trait="signup-from-email-only-exit-intent-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Get Free Shipping"
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Email Only Exit Intent"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={() => onChangeViewHandler('login')}
          />
        </div>
      </div>

      <div className="ExitIntentEmailStep__footer fg-condensed--medium">
        *Offer excludes international and expedited orders.
        <br />
        By continuing you agree to our{' '}
        <AutoLink to="/terms" target="_blank">
          terms and conditions
        </AutoLink>
      </div>
    </div>
  );
};

export default ExitIntentEmailStep;
