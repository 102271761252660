// @flow

import uniqBy from 'lodash/uniqBy';

import type {
  AccountState,
  AccountAction,
  RestockNotificationType
} from './types';

const initialState: AccountState = {
  email: '',
  storeCredit: '$0.00',
  message: '',
  idMeUrl: '',
  frequencyStatus: null,
  restockNotifications: [],
  usersCategories: []
};

const reducer = (state: AccountState = initialState, action: AccountAction) => {
  switch (action.type) {
    case 'ACCOUNT_DETAILS/FETCH':
    case 'ACCOUNT_DETAILS/UPDATE':
    case 'RESTOCK_NOTIFICATION/DELETE':
      return { ...state, message: '' };
    case 'ORDER_HISTORY/FETCH':
    case 'ACCOUNT_LIST/FETCH':
      return { ...state, loading: true };
    case 'ACCOUNT_DETAILS/UPDATE_SUCCESS':
      return { ...state, ...action.data, message: 'Account updated' };
    case 'ACCOUNT_DETAILS/FETCH_SUCCESS':
    case 'ORDER_HISTORY/FETCH_SUCCESS':
      return { ...state, ...action.data, loading: false, message: '' };
    case 'ACCOUNT_LIST/FETCH_SUCCESS':
      return {
        ...state,
        restockNotifications: uniqBy<RestockNotificationType>(
          action.data,
          (item) => item.restockNotificationId
        ),
        message: '',
        loading: false
      };
    case 'ACCOUNT_DETAILS/UPDATE_FAILURE':
    case 'ACCOUNT_DETAILS/FETCH_FAILURE':
    case 'RESTOCK_NOTIFICATION/DELETE_FAILURE':
      return { ...state, message: action.err };
    case 'ORDER_HISTORY/FETCH_FAILURE':
    case 'ACCOUNT_LIST/FETCH_FAILURE':
      return { ...state, message: action.err, loading: false };

    case 'EMAIL_FREQUENCY/UPDATE':
      return { ...state, frequencyStatus: 'submitted' };
    case 'EMAIL_FREQUENCY/UPDATE_SUCCESS':
      return { ...state, frequencyStatus: 'success' };
    case 'EMAIL_FREQUENCY/UPDATE_FAILURE':
      return { ...state, frequencyStatus: 'failure' };

    case 'RESTOCK_NOTIFICATION/DELETE_SUCCESS': {
      const remainingNotifs: Array<RestockNotificationType> = state.restockNotifications.filter(
        (notif) => notif.restockNotificationId !== parseInt(action.id)
      );
      return {
        ...state,
        restockNotifications: remainingNotifs,
        message: 'Notification deleted'
      };
    }
    default:
      return state;
  }
};

export default reducer;
