// @flow

import './JournalLoginView.scss';

import * as React from 'react';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';
import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import AuthForm from 'components/Shared/AuthForm';

import { type Views } from 'modules/auth-modal/types';

const modalViewText = { title: 'Welcome Back', button: 'Log In' };

type Props = {|
  onChangeView: (view: Views) => void
|};

const JournalLoginView = ({ onChangeView }: Props) => (
  <div className="JournalLoginView tc">
    <div className="JournalLoginView__wrapper">
      <h3 className="JournalLoginView__title fg-condensed--medium fw--600">
        {modalViewText.title}
      </h3>
      <div className="JournalLoginView__auth--wrapper">
        <AuthenticationContainer trait="signup-from-journal-soft-gate-modal">
          {(authenticationProps: AuthenticationProps) => (
            <AuthForm
              view="login"
              textColor="dark"
              onChangeView={onChangeView}
              submitText={modalViewText.button}
              {...authenticationProps}
            />
          )}
        </AuthenticationContainer>
      </div>
      <div className="JournalLoginView__footer fg-condensed--body">
        Not a Member?{' '}
        <p
          onClick={() => onChangeView('signup')}
          className="JournalLoginView__footer--cta link--nudge"
        >
          Sign Up
        </p>
      </div>
    </div>
  </div>
);

export default JournalLoginView;
