// @flow

import './DefaultUpdateEmailView.scss';

import * as React from 'react';
import { useSelector } from 'react-redux';

import AuthenticationContainer from 'components/Authentication/AuthenticationContainer';
import { type AuthenticationProps } from 'modules/authentication/authentication-flow-types';
import EmailForm from 'components/Shared/AuthForm/TwoStepSignup/EmailForm';
import CTALink from 'components/Shared/AuthForm/CTALink';

import type { Views } from 'modules/auth-modal/types';

type Props = {|
  onChangeView: (Views) => void
|};

const DefaultUpdateEmailView = ({ onChangeView }: Props) => {
  const authModalEmail: string | null = useSelector(
    (state) => state.authModal.email
  );

  React.useEffect(() => {
    if (!authModalEmail) {
      onChangeView('signup');
    }
  }, [authModalEmail, onChangeView]);

  const onChangeViewHandler = React.useCallback(() => onChangeView('login'), [
    onChangeView
  ]);

  const onEmailChangedHandler = React.useCallback(
    () => onChangeView('signup'),
    [onChangeView]
  );

  return (
    <div className="DefaultUpdateEmailView">
      <div className="DefaultUpdateEmailView__wrapper tc">
        <p className="DefaultUpdateEmailView__title fg-condensed--medium fw--600">
          Update Your Email
        </p>
        <div className="DefaultUpdateEmailView__form">
          <AuthenticationContainer trait="signup-from-email-only-soft-gate-modal">
            {({
              onClickFacebookSignup,
              onClickGoogleSignup
            }: AuthenticationProps) => (
              <EmailForm
                buttonValue="Continue account"
                userEmail={authModalEmail}
                onEmailChanged={onEmailChangedHandler}
                onClickFacebookSignup={onClickFacebookSignup}
                onClickGoogleSignup={onClickGoogleSignup}
                trigger="Update Email View"
              />
            )}
          </AuthenticationContainer>
          <CTALink
            textBefore="Already a member?"
            linkText="Log in"
            onClick={onChangeViewHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultUpdateEmailView;
