// @flow

import type { Saga } from 'redux-saga';
import { call, takeEvery, put } from 'redux-saga/effects';

import {
  fetchAccountDetails,
  updateAccountDetails,
  fetchOrderHistory,
  updateEmailFrequency,
  fetchAccountList,
  deleteRestockNotification
} from './requests';
import {
  getAccountDetailsSuccess,
  getAccountDetailsFailure,
  updateAccountDetailsSuccess,
  updateAccountDetailsFailure,
  getOrderHistorySuccess,
  getOrderHistoryFailure,
  updateEmailFrequencySuccess,
  updateEmailFrequencyFailure,
  getAccountListSuccess,
  getAccountListFailure,
  deleteNotificationSuccess,
  deleteNotificationFailure
} from './action-creators';

function* getAccountDetails() {
  try {
    const response = yield call(fetchAccountDetails);
    const { data } = response.data;
    yield put(getAccountDetailsSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(getAccountDetailsFailure(error));
  }
}

function* updateAccountDetailsSaga(action) {
  const accountData = action.data;

  try {
    const response = yield call(updateAccountDetails, accountData);
    const { data } = response.data;
    yield put(updateAccountDetailsSuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(updateAccountDetailsFailure(error));
  }
}

function* getOrderHistory(action) {
  try {
    const response = yield call(fetchOrderHistory, action.monthsBack);
    const { data } = response.data;
    yield put(getOrderHistorySuccess(data));
  } catch (err) {
    const { error } = err.response.data;
    yield put(getOrderHistoryFailure(error));
  }
}

function* updateEmailFrequencySaga(action) {
  try {
    yield call(updateEmailFrequency, action.data);
    yield put(updateEmailFrequencySuccess());
  } catch {
    yield put(updateEmailFrequencyFailure());
  }
}

function* getAccountListSaga() {
  try {
    const response = yield call(fetchAccountList);
    const { data } = response.data;
    yield put(getAccountListSuccess(data));
  } catch (err) {
    yield put(getAccountListFailure(err.response.data.error));
  }
}

function* deleteRestockNotificationSaga(action) {
  try {
    const response = yield call(deleteRestockNotification, action.id);
    yield put(deleteNotificationSuccess(response.data.id));
  } catch (err) {
    yield put(deleteNotificationFailure(err.response.data.error));
  }
}

function* accountSaga(): Saga {
  yield takeEvery('ACCOUNT_DETAILS/FETCH', getAccountDetails);
  yield takeEvery('ACCOUNT_DETAILS/UPDATE', updateAccountDetailsSaga);
  yield takeEvery('ORDER_HISTORY/FETCH', getOrderHistory);
  yield takeEvery('EMAIL_FREQUENCY/UPDATE', updateEmailFrequencySaga);
  yield takeEvery('ACCOUNT_LIST/FETCH', getAccountListSaga);
  yield takeEvery('RESTOCK_NOTIFICATION/DELETE', deleteRestockNotificationSaga);
}

export default accountSaga;
